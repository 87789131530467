<template>
  <div class="create-faq">
    <AssetAppbar />
    <div class="ma-4">
      <AssetForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create Hub FAQ
 * ==================================================================================
 **/

import AssetAppbar from '@/views/Home/Maintenance/Assets/components/AssetAppbar.vue'
import AssetForm from '@/views/Home/Maintenance/Assets/components/AssetForm.vue'

export default {
  components: {
    AssetAppbar,
    AssetForm,
  },
}
</script>
<style lang="scss">
.create-faq {
  //
}
</style>
