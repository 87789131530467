<template>
  <div class="asset-form">
    <div ref="form" class="asset-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">{{ header }}</h3>

      <v-row>
        <v-col cols="12" md="5">
          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Building</label>
          <SelectBuilding
            flat
            solo
            required
            class="mt-2"
            v-model="form.building_id"
            :pre-select="!isUpdate"
            :error-messages="form.$getError('building_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
          />

          <span class="red--text"><strong>* </strong></span>
          <label class="text-field-label">Asset Name</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Name"
            v-model="form.name"
            :error-messages="form.$getError('name')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <label class="text-field-label">Asset Location</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Location"
            v-model="form.location"
            :error-messages="form.$getError('location')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <v-row>
            <v-col cols="12" md="6">
              <label class="text-field-label">Asset Make</label>
              <v-text-field
                flat
                solo
                required
                class="mt-2"
                placeholder="Make"
                v-model="form.asset_make"
                :error-messages="form.$getError('asset_make')"
                :loading="form.$busy"
                :disabled="form.$busy"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <label class="text-field-label">Asset Model</label>
              <v-text-field
                flat
                solo
                required
                class="mt-2"
                placeholder="Model"
                v-model="form.asset_model"
                :error-messages="form.$getError('asset_model')"
                :loading="form.$busy"
                :disabled="form.$busy"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <label class="text-field-label">Serial No.</label>
              <v-text-field
                flat
                solo
                required
                class="mt-2"
                placeholder="Serial No."
                v-model="form.serial_no"
                :error-messages="form.$getError('serial_no')"
                :loading="form.$busy"
                :disabled="form.$busy"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <label class="text-field-label">Barcode No.</label>
              <v-text-field
                flat
                solo
                required
                class="mt-2"
                placeholder="Barcode No."
                v-model="form.barcode_no"
                :error-messages="form.$getError('barcode_no')"
                :loading="form.$busy"
                :disabled="form.$busy"
              ></v-text-field>
            </v-col>
          </v-row>

          <label class="text-field-label">Asset Description</label>
          <v-textarea
            flat
            solo
            required
            rows="2"
            class="mt-2"
            v-model="form.description"
            :error-messages="form.$getError('description')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-textarea>

          <label class="text-field-label">Notes</label>
          <v-textarea
            flat
            solo
            required
            class="mt-2"
            v-model="form.note"
            :error-messages="form.$getError('note')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="1" />
        <v-col cols="12" md="6">
          <AssetFileUpload
            ref="imagesUploader"
            class="my-2"
            :loading="form.$busy"
            :error="form.$getError('images')"
            :for-update="isUpdate"
            @updated="form.$clearError('images')"
          />

          <v-col class="table_document_data mt-10" cols="12">
            <v-row class="row_documents_table">
              <v-col class="font_weight_700" cols="12" md="6">Documents</v-col>
              <v-col
                class="button_document_table_create text-end"
                cols="12"
                md="6"
              >
                <v-btn
                  v-if="hasPermissions"
                  color="primary"
                  class="appbar__btn"
                  @click="dialogUpdate = true"
                >
                  <v-icon class="mr-2">{{ icons.add }}</v-icon>
                  Add Documents
                </v-btn>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headerDocuments"
              :items="isUpdate ? documents : tempDocuments"
              v-model="selected"
              :options.sync="options"
              :footer-props="{
                'items-per-page-options': [5, 10, 15, -1],
              }"
              :items-per-page="5"
              :loading="loading"
              @update:options="fetchDocuments"
              :server-items-length="
                isUpdate ? totalDocuments : tempDocuments.length
              "
            >
              <template v-slot:item.file="{ item }">
                <div v-if="item.file">{{ item.file.name }}</div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="5">
          <div class="mt-4">
            <v-btn
              v-if="hasPermissions"
              type="submit"
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              :loading="form.$busy"
              @click="submit"
              >{{ buttonLabel }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="dialogUpdate" max-width="600px">
      <v-form
        ref="formDocumentsFile"
        class="faq-form__form"
        @submit.prevent="submitDocumentsFile"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Open Dialog
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">New Document</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <label class="text-field-label">File Input</label>
                  <v-file-input
                    v-model="formDocumentsFile.file"
                    :loading="formDocumentsFile.$busy"
                    :disabled="formDocumentsFile.$busy"
                    class="mt-2"
                    flat
                    solo
                    clearable
                    show-close="false"
                    :error-messages="
                      isFileTooLarge
                        ? 'The file must be less than 4MB'
                        : formDocumentsFile.$getError('file')
                    "
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <label class="text-field-label">Title</label>
                  <v-text-field
                    flat
                    solo
                    required
                    class="mt-2"
                    placeholder="Title"
                    v-model="formDocumentsFile.title"
                    :loading="formDocumentsFile.$busy"
                    :disabled="formDocumentsFile.$busy"
                    :error-messages="formDocumentsFile.$getError('title')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 "
              text
              @click="cancelDialogUpdate()"
            >
              Cancel
            </v-btn>
            <v-btn
              class="text_uppercase"
              color="blue darken-1 text-uppercase"
              text
              @click="submitDocumentsFile()"
              name="submitFormDocument"
              :loading="formDocumentsFile.$busy"
              :disabled="
                isFileTooLarge ||
                !formDocumentsFile.title ||
                !formDocumentsFile.file
              "
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="600px">
      <v-form
        ref="formDocumentsFile"
        class="faq-form__form"
        @submit.prevent="submitDocumentsFile"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5"
              >Are you sure to delete this document(s) ?</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelDialogDelete()">
              No
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="confirmDelete()"
              name="submitFormDocument"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Bookable Form
 * ==================================================================================
 **/
import { mapState, mapActions, mapMutations } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import SelectBuilding from '@/components/fields/SelectBuilding'
import AssetFileUpload from '@/views/Home/Maintenance/Assets/components/AssetFileUpload.vue'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import { mdiPlus } from '@mdi/js'

export default {
  components: {
    SelectBuilding,
    AssetFileUpload,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    asset: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      form: new Form({
        building_id: '',
        name: '',
        location: '',
        asset_make: '',
        asset_model: '',
        serial_no: '',
        barcode_no: '',
        description: '',
        note: '',
        images: [],
        asset_document_ids: [],
      }),
      formDocumentsFile: new Form({
        file: null,
        title: '',
      }),
      headerDocuments: [
        {
          text: 'Title',
          value: 'title',
          width: '120px',
        },
        {
          text: 'File name',
          value: 'file',
          width: '150px',
        },
        {
          text: 'Actions',
          value: 'actions',
          width: '150px',
          align: 'end',
          sortable: false,
        },
      ],
      readonlyHeaders: [
        {
          text: 'Title',
          value: 'title',
          width: '120px',
        },
        {
          text: 'File name',
          value: 'file',
          width: '150px',
        },
      ],
      selected: [],
      loading: false,
      dialogUpdate: false,
      isUpdateDocuments: false,
      dialogDelete: false,
      deleteDocumentId: '',
      options: {},
      icons: {
        add: mdiPlus,
      },
    }
  },

  created() {
    this.clearTempDocumentsList()
    this.clearDocuments()
    this.fetchDocuments()
  },

  computed: {
    ...mapState({
      createdAsset: (state) => state.asset.assetDetails,
      permissions: (state) => state.auth.permissions,
      totalDocuments: (state) => state.assetDocument.totalDocuments,
      documents: (state) => state.assetDocument.list,
      createdDocument: (state) => state.assetDocument.documentDetails,
      errorDocument: (state) => state.assetDocument.error,
      tempDocuments: (state) => state.assetDocument.tempList,
    }),

    header() {
      return this.isUpdate ? 'Asset Information' : 'Create Asset'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updateAsset : this.createAsset
    },

    submitApiDocumentsFile() {
      return this.isUpdateDocuments ? this.updateDocument : this.createDocument
    },

    clearTempDocumentsList() {
      return this.clearTempDocuments
    },

    isUpdate() {
      return !!this.asset
    },

    hasPermissions() {
      return validatePermissions([PERMISSION.ASSETS_CREATE], this.permissions)
    },
    isFileTooLarge() {
      if (this.formDocumentsFile.file) {
        return this.formDocumentsFile.file.size > 4 * 1024 * 1024
      }
      return false
    },
  },

  watch: {
    asset(newValue, oldValue) {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      createAsset: 'asset/createAsset',
      updateAsset: 'asset/updateAsset',
      getDocuments: 'assetDocument/getDocuments',
      createDocument: 'assetDocument/createDocument',
      updateDocument: 'assetDocument/updateDocument',
      deleteDocument: 'assetDocument/deleteDocument',
      clearTempDocuments: 'document/clearTempDocuments',
      deleteTempDocument: 'document/deleteTempDocument',
    }),

    ...mapMutations({
      clearDocuments: 'document/clearDocumentList',
    }),

    initForm() {
      if (this.asset) {
        this.form.building_id = this.asset.building_id
        this.form.name = this.asset.name
        this.form.location = this.asset.location
        this.form.asset_make = this.asset.asset_make
        this.form.asset_model = this.asset.asset_model
        this.form.serial_no = this.asset.serial_no
        this.form.barcode_no = this.asset.barcode_no
        this.form.description = this.asset.description
        this.form.note = this.asset.note
      }
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearErrors()

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('Service & Space details successfully updated!')
          } else {
            this.showSnackbar('Service & Space successfully created!')
            this.$router.push({
              name: 'maintenance.assets',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    async fetchDocuments(options, page = 1) {
      if (this.loading) return
      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalDocuments
            : options.itemsPerPage
          : 5,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
        assetId: this.$route?.params.id || null,
      }

      this.loading = true
      await this.getDocuments(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    async submitDocumentsFile() {
      if (this.formDocumentsFile.$busy) return
      this.formDocumentsFile.$busy = true
      this.formDocumentsFile.$clearErrors()
      let forUpdate = this.isUpdateDocuments
      const formData = await this.getFormDocumentData()
      const assetId = this?.asset?.id || 0
      const id = this?.formDocumentsFile?.id || null
      await this.submitApiDocumentsFile({ formData, assetId, id })
        .then((res) => {
          if (this.errorDocument) {
            this.showSnackbar(this.errorDocument, false)
            this.clearError()
          } else {
            if (forUpdate) {
              this.formDocumentsFile.$busy = false
              this.showSnackbar('Document details successfully updated!')
            } else {
              this.showSnackbar('Document successfully created!')
            }
          }
          this.isUpdateDocuments = false
          this.dialogUpdate = false
          this.fetchDocuments()
          this.formDocumentsFile = new Form({
            file: '',
            title: '',
          })
        })
        .catch((err) => {
          this.formDocumentsFile.$busy = false
          this.formDocumentsFile.$setErrors(this.getValidationErrors(err))
        })
    },

    async getFormDocumentData() {
      const data = new Promise((resolve, reject) => {
        let formDocumentsFile = this.formDocumentsFile.$data()
        let file = formDocumentsFile.file
        const assetId = this?.asset?.id || 0

        if (file instanceof Blob || file instanceof File) {
          const fileReader = new FileReader()
          fileReader.onload = function () {
            let byteArray = new Uint8Array(fileReader.result)
            const blob = new Blob([byteArray], {
              type: 'application/octet-stream',
            })
            const fileName = file.name

            const newFile = new File([blob], fileName, {
              type: blob.type,
              lastModified: Date.now(),
            })

            const formData = new FormData()
            formData.append('file', newFile)
            formData.append('asset_id', assetId)
            formData.append('title', formDocumentsFile.title)

            if (this.formDocumentsFile?.id) {
              formData.id = this.formDocumentsFile.id
            }

            resolve(formData)
          }

          fileReader.onerror = function (error) {
            reject(error)
          }

          fileReader.readAsArrayBuffer(file)
        } else {
          // If the file is not a Blob or File, exclude it from FormData
          const formData = new FormData()
          formData.append('title', formDocumentsFile.title)
          formData.append('asset_id', assetId)

          if (this.formDocumentsFile?.id) {
            formData.id = this.formDocumentsFile.id
          }

          resolve(formData)
        }
      })

      return data
    },

    editItem(item) {
      this.dialogUpdate = true
      this.isUpdateDocuments = true
      this.formDocumentsFile.title = item.title
      this.formDocumentsFile.file = item.file
      this.formDocumentsFile.asset_id = item.asset_id
      this.formDocumentsFile.id = item.id
    },

    cancelDialogDelete() {
      this.dialogDelete = false
    },

    deleteItem(item) {
      this.dialogDelete = true
      this.deleteDocumentId = item.id
    },

    async confirmDelete() {
      await this.deleteDocument(this.deleteDocumentId).then(() => {
        this.deleteTempDocument(this.deleteDocumentId)
        this.showSnackbar('Delete document successfully!')
        this.dialogDelete = false
        this.fetchDocuments()
      })
    },

    cancelDialogUpdate() {
      this.dialogUpdate = false
      this.isUpdateDocuments = false
      this.formDocumentsFile = new Form({
        file: '',
        title: '',
      })
    },

    /**
     * Validate form image values on create only
     * @return {Boolean}
     */
    validate() {
      this.form.$clearErrors()

      return !this.form.$hasErrors()
    },

    getFormData() {
      let form = this.form.$data()
      delete form.images

      form.asset_document_ids = this.tempDocuments.map((item) => item.id)

      if (this.isUpdate) {
        form.id = this.asset.id
        return form
      } else {
        const formData = new FormData()
        for (var field in form) {
          if (Array.isArray(form[field])) {
            form[field].forEach((value, index) => {
              formData.append(`${field}[]`, value)
            })
          } else {
            formData.append(field, form[field])
          }
        }

        this.$refs.imagesUploader.getImages(formData)

        return formData
      }
    },
  },
}
</script>
<style lang="scss">
.asset-form {
  .hint {
    font-size: 12px;
    /* Adjust the font size as needed */
    color: #888;
    /* Choose a color for the hint text */
  }
}

.font_weight_700 {
  font-weight: 700;
}
</style>
